interface IProps {
  show: boolean;
  values: any;
  errors?: any;
  onChange: (e: any) => void;
}

export default function FilesCard({ values, onChange, show, errors }: IProps) {
  // const handleChange = (e: any) => {
  //   onChange({ ...values, [e.target.name]: e.target.value });
  // };

  const handleFile = (e: any) => {
    onChange({ ...values, [e.target.name]: e.target.files[0] });
  };

  return (
    <div className={show ? "block" : "hidden"}>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="file"
          accept=".xls, .xlsx"
          name="file_peserta"
          id="file_1"
          className={`${
            errors?.file_peserta ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-xs text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          // value={values?.file_peserta || ""}
          onChange={handleFile}
        />
        <label
          htmlFor="file_1"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.file_peserta
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Upload File Template Excel
        </label>
        <p
          className={`${
            errors?.file_peserta ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.file_peserta}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="file"
          accept=".pdf"
          name="file_choir"
          id="file_choir"
          className={`${
            errors?.file_choir ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-xs text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          // value={values?.file_choir || ""}
          onChange={handleFile}
        />
        <label
          htmlFor="file_choir"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.file_choir
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Upload Photo Choir
        </label>
        <p
          className={`${
            errors?.file_choir ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.file_choir}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="file"
          accept=".pdf"
          name="file_logo_choir"
          id="file_logo_choir"
          className={`${
            errors?.file_logo_choir ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-xs text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          // value={values?.file_logo_choir || ""}
          onChange={handleFile}
        />
        <label
          htmlFor="file_logo_choir"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.file_logo_choir
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Upload Logo Choir
        </label>
        <p
          className={`${
            errors?.file_logo_choir ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.file_logo_choir}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="file"
          accept=".pdf"
          name="file_ktp"
          id="file_ktp"
          className={`${
            errors?.file_ktp ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-xs text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          // value={values?.file_ktp || ""}
          onChange={handleFile}
        />
        <label
          htmlFor="file_ktp"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.file_ktp
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Upload Scan Kartu Identitas Peserta (Dijadikan satu PDF)
        </label>
        <p
          className={`${
            errors?.file_ktp ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.file_ktp}
        </p>
      </div>
    </div>
  );
}
