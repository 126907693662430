import React, { useEffect, useRef, useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";

const ChangesPassword: React.FC = () => {
  const [show, setShow] = useState(false);
  const { state } = useNavigation();
  const actionRes: any = useActionData();
  const loader: any = useLoaderData();

  const formRef = useRef<any>(null);

  const navList = [
    { label: "Beranda", url: "/" },
    { label: "Workshop", url: "/class" },
    { label: "Jadwal", url: "/jadwal" },
    { label: "Juri", url: "/juri" },
    { label: "Kontak", url: "/kontak" },
  ];

  // console.log(actionRes);
  useEffect(() => {
    if (actionRes?.status === 200) formRef.current.reset();
  }, [actionRes]);

  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-black opacity-60"></div>
      <Header
        navs={navList}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="bg-black bg-cover lg:h-screen">
        <div className="relative py-8 lg:py-16 px-4 mx-auto max-w-sm">
          <h4 className="mb-8 text-2xl tracking-tight font-extrabold text-center text-white">
            Ubah Password
          </h4>
          <div className="relative overflow-x-auto">
            {actionRes?.status !== 201 && (
              <div
                className={`${actionRes?.data?.message ? " " : "hidden "} ${
                  actionRes?.status === 200
                    ? "text-green-800 bg-green-50 "
                    : "text-red-800 bg-red-50 "
                } p-4 m-2 text-sm  rounded-lg `}
                role="alert"
              >
                {actionRes?.data?.message}
              </div>
            )}
            <Form
              className="p-2 flex flex-col gap-2 space-y-2"
              method="PUT"
              ref={formRef}
            >
              <div>
                <label
                  htmlFor="old-password"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Password Lama
                </label>
                <input
                  type="password"
                  name="old_password"
                  id="old-password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
                <p
                  className={`${
                    actionRes?.errors?.old_password ? "block" : "hidden"
                  } text-red-500 text-sm italic pt-1`}
                >
                  {actionRes?.errors?.old_password}
                </p>
              </div>
              <div>
                <label
                  htmlFor="new_password"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Password Baru
                </label>
                <input
                  type="password"
                  name="new_password"
                  id="new_password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
                <p
                  className={`${
                    actionRes?.errors?.new_password ? "block" : "hidden"
                  } text-red-500 text-sm italic pt-1`}
                >
                  {actionRes?.errors?.new_password}
                </p>
              </div>
              <div>
                <label
                  htmlFor="reNew_password"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Ketik ulang password baru
                </label>
                <input
                  type="password"
                  name="reNew_password"
                  id="reNew_password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
                <p
                  className={`${
                    actionRes?.errors?.reNew_password ? "block" : "hidden"
                  } text-red-500 text-sm italic pt-1`}
                >
                  {actionRes?.errors?.reNew_password}
                </p>
              </div>
              <button
                disabled={state === "submitting" || state === "loading"}
                className="text-white text-md bg-blue-600 hover:bg-blue-700 rounded-lg px-5 py-2.5 text-center"
                type="submit"
              >
                Simpan
              </button>
            </Form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ChangesPassword;
