import { useEffect, useState } from "react";
import PreviewModal from "./PreviewModal";
import { Item } from "../Item";
import { Divider } from "../Divider";
import ValidasiConfirmModal from "./ValidasiConfirmModal";
import DetailMemberEventEditModal from "./DetailMemberEventEditModal";
import { useActionData } from "react-router-dom";

interface IProps {
  open: boolean;
  data?: any;
  onOk?: () => void;
  onCancel?: () => void;
}

export default function DetailMemberEventModal(props: IProps) {
  const actionRes: any = useActionData();

  const [dataForm, setDataForm] = useState<any>(props?.data);
  const [showEdit, setShowEdit] = useState(false);
  const [showValidasi, setShowValidasi] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [filename, setFilename] = useState<string>();
  const [currentForm, setCurrentForm] = useState<number>(0);

  useEffect(() => {
    setDataForm(props?.data);
  }, [props?.data]);

  useEffect(() => {
    if (actionRes?.status === 200) {
      setDataForm({
        ...dataForm,
        ...actionRes?.data?.data,
        validate: actionRes?.data?.data?.validate === "true" ? true : false,
      });
      setShowValidasi(false);
      setShowPreview(false);
      setShowEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionRes]);

  return (
    <>
      <div
        id="default-modal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          props.open ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center w-screen md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div className="relative p-4 w-full h-full max-w-2xl max-h-full m-auto">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
              <h3 className="flex gap-4 text-xl font-semibold text-gray-900 ">
                Data Peserta{" "}
                <div
                  className={`${
                    dataForm?.validate ? "block" : "hidden"
                  } flex gap-2`}
                >
                  <svg
                    className="w-6 h-6 text-green-800"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                    />
                  </svg>
                  <span className="text-green-800">Approve</span>
                </div>
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                data-modal-hide="default-modal"
                onClick={props.onCancel}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-2 md:p-3 space-y-4 text-sm font-normal">
              <Divider
                title="Data Choir"
                validasi={dataForm?.validate}
                onClick={() => {
                  setCurrentForm(1);
                  setShowEdit(true);
                }}
              />
              <ul className="px-2 md:px-4 space-y-2">
                <li className="flex items-center">
                  <Item label="Kategori" value={dataForm?.category} />
                </li>
                <li className="grid grid-cols-2  items-center">
                  <Item label="Nama Choir" value={dataForm?.choir_name} />
                  <Item label="Nama PIC" value={dataForm?.name} />
                </li>
                <li className="grid grid-cols-2  items-center">
                  <Item label="Email" value={dataForm?.email} />
                  <Item label="No Phone/WA" value={dataForm?.contact} />
                </li>
                <li className="flex items-center">
                  <Item label="Alamat" value={dataForm?.address} />
                </li>
                <li className="grid grid-cols-2 items-center">
                  <Item label="Kota" value={dataForm?.city} />
                  <Item label="Instansi" value={dataForm?.instance} />
                </li>
              </ul>
              <Divider
                title="Lagu"
                validasi={dataForm?.validate}
                onClick={() => {
                  setCurrentForm(2);
                  setShowEdit(true);
                }}
              />
              <ul className="px-2 md:px-4 space-y-2">
                <li className="grid grid-cols-2  items-center">
                  <Item
                    label="Lagu 1"
                    value={dataForm?.song_1}
                    onFilePreview={() =>
                      setFilename(dataForm?.file_song_1 as string)
                    }
                    onOpenPreview={setShowPreview}
                  />
                  <Item
                    label="Lagu 2"
                    value={dataForm?.song_2}
                    onFilePreview={() =>
                      setFilename(dataForm?.file_song_2 as string)
                    }
                    onOpenPreview={setShowPreview}
                  />
                </li>
              </ul>
              <Divider
                title="File"
                validasi={dataForm?.validate}
                onClick={() => {
                  setCurrentForm(3);
                  setShowEdit(true);
                }}
              />
              <ul className="px-2 md:px-4 space-y-2">
                <li>
                  <Item
                    label="Data peserta"
                    value={dataForm?.file_peserta}
                    onFilePreview={() =>
                      setFilename(dataForm?.file_peserta as string)
                    }
                    onOpenPreview={setShowPreview}
                  />
                </li>
                <li>
                  <Item
                    label="Photo Choir"
                    value={dataForm?.file_choir}
                    onFilePreview={() =>
                      setFilename(dataForm?.file_choir as string)
                    }
                    onOpenPreview={setShowPreview}
                  />
                </li>
                <li>
                  <Item
                    label="Logo Choir"
                    value={dataForm?.file_logo_choir}
                    onFilePreview={() =>
                      setFilename(dataForm?.file_logo_choir as string)
                    }
                    onOpenPreview={setShowPreview}
                  />
                </li>
                <li>
                  <Item
                    label="Scan Kartu Identitas
                Peserta"
                    value={dataForm?.file_ktp}
                    onOpenPreview={setShowPreview}
                  />
                </li>
              </ul>
              <Divider
                title="Pembayaran"
                validasi={dataForm?.validate}
                onClick={() => {
                  setCurrentForm(4);
                  setShowEdit(true);
                }}
              />
              <ul className="px-2 md:px-4 space-y-2">
                <li className="grid grid-cols-2  items-center">
                  <Item label="Bank" value={dataForm?.bank} />
                  <Item label="Nama Pemilik" value={dataForm?.owner_rek} />
                </li>
                <li className="items-center">
                  <Item
                    label="Bukti pembayaran"
                    value={dataForm?.file_bill}
                    onFilePreview={() =>
                      setFilename(dataForm?.file_bill as string)
                    }
                    onOpenPreview={setShowPreview}
                  />
                </li>
              </ul>
            </div>
            <div
              className={`flex items-center ${
                dataForm?.validate ? "justify-end" : "justify-between"
              }  flex-row p-4 md:p-5 border-t border-gray-200 rounded-b `}
            >
              <button
                data-modal-hide="default-modal"
                type="button"
                onClick={() => setShowValidasi(true)}
                className={`${
                  dataForm?.validate ? "hidden" : "block"
                } text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              >
                Validasi
              </button>
              <button
                data-modal-hide="default-modal"
                type="button"
                onClick={props.onCancel}
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
              >
                Selesai
              </button>
            </div>
          </div>
        </div>
      </div>
      <PreviewModal
        open={showPreview}
        onCancel={() => {
          setFilename(undefined);
          setShowPreview(false);
        }}
        filename={filename}
      />
      <ValidasiConfirmModal
        data={props?.data}
        open={showValidasi}
        onCancel={() => setShowValidasi(false)}
      />
      <DetailMemberEventEditModal
        open={showEdit}
        data={props?.data}
        currentForm={currentForm}
        onCancel={() => setShowEdit(false)}
      />
    </>
  );
}
