import { LoaderFunctionArgs, json } from "react-router-dom";

import { createPic } from "./pic.action";

export async function registerAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());
  data.id =
    data.id === undefined || data.id === "undefined" ? undefined : data.id;
  const response: any = await createPic(data);
  return json({ ...response });
}
