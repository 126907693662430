import { LoaderFunctionArgs, json } from "react-router-dom";

import httpCommon from "./http-common";

export async function homeAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());

  data.id =
    data.id === undefined || data.id === "undefined" ? undefined : data.id;
  const response: any = await createWorkshop(data);
  console.log(response);
  return json({ ...response });
  // return json({ ...data });
}

export const createWorkshop = async (data: any) => {
  try {
    return await httpCommon.post("/choir", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error: any) {
    return error?.response;
  }
};
