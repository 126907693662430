import React, { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

const SchedulePage: React.FC = () => {
  const [show, setShow] = useState(false);
  const loader: any = useLoaderData();

  const navList = [
    { label: "Beranda", url: "/" },
    { label: "Workshop", url: "/class" },
    { label: "Jadwal", url: "/jadwal" },
    // { label: "Register", url: "/register" },
    { label: "Juri", url: "/juri" },
    { label: "Kontak", url: "/kontak" },
  ];
  const schdules = [
    { time: "14th Sep 2024", title: "Registrasi" },
    { time: "15th Sep 2024", title: "Kompetisi" },
  ];
  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-black opacity-60"></div>
      <Header
        navs={navList}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="bg-schedule bg-cover h-screen">
        <div className="fixed w-full h-full bg-black opacity-45"></div>
        <div className="relative max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold leading-tight tracking-tight text-white">
              Jadwal
            </h2>
            {/* <p className="font-light sm:text-xl text-gray-400">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
          </div>

          <div className="flow-root max-w-3xl mx-auto mt-8 md:mt-12 lg:mt-16">
            <div className="-my-4 divide-y divide-gray-300 gap-2">
              {schdules.map((schdule, idx) => (
                <div
                  className="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center"
                  key={idx}
                >
                  <p className="w-52 text-lg font-normal sm:text-right text-gray-400 shrink-0">
                    {schdule.time}
                  </p>
                  <h3 className="text-lg font-semibold text-white flex-1">
                    <Link to="#" className="hover:underline">
                      {schdule.title}
                    </Link>
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SchedulePage;
