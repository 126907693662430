import { useCallback, useState } from "react";

interface IProps {
  show: boolean;
  values: any;
  onChange: (e: any) => void;
}

export default function WorkshopDetailsCard({
  values,
  onChange,
  show,
}: IProps) {
  const [learns, setLearns] = useState<string[]>(
    values.learns ? JSON.parse(values.learns) : []
  );
  const handleChange = (e: any) => {
    onChange({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangesLearn = useCallback(
    (title: string) => {
      learns.includes(title)
        ? setLearns(() => [...learns.filter((tl: any) => tl !== title)])
        : setLearns((prev) => [...prev, title]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [learns]
  );

  const classs: any[] = [
    {
      label: "VOCAL CLASS OLEH RONI SUGIARTO",
      description: ["Biaya Rp. 250.000/orang."],
      price: 250000,
    },
    {
      label: "CONDUCTING CLASS OLEH OMID MOHEB-ZADEH, M. MUS",
      description: ["Biaya Rp. 250.000/orang."],
      price: 250000,
    },
    {
      label: "GREGORIAN CLASS OLEH MR. GREGORIUS GERALD PRATOMO, M. MUS",
      description: ["Biaya Rp. 250.000/orang."],
      price: 250000,
    },
    {
      label:
        "KAJIAN REPERTOAR DAN ARANSEMEN OLEH AGASTYA RAMA LISTYA, MSM. PH. D",
      description: ["Biaya Rp. 250.000/orang."],
      price: 250000,
    },
  ];

  return (
    <div className={show ? "block" : "hidden"}>
      <p className="mb-5 text-sm font-medium text-gray-900">Pilih workhsop</p>
      <input type="hidden" name="learns" value={JSON.stringify(learns)} />
      <div className="mb-5 flex flex-col gap-2">
        {classs.map((dt: any, idx: number) => (
          <div className="flex items-center" key={idx}>
            <input
              id="default-checkbox"
              type="checkbox"
              checked={values?.learns?.includes(dt.label.replaceAll(" ", "_"))}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 dark:b"
              onChange={() => handleChangesLearn(dt.label.replaceAll(" ", "_"))}
            />
            <div className="flex justify-between">
              <label
                htmlFor="checked-checkbox"
                className="ms-2 text-sm font-semibold text-gray-900 "
              >
                {dt.label}
              </label>
              <div className="ms-2 text-xs">- {dt.description[0]}</div>
            </div>
          </div>
        ))}
      </div>
      <p className="my-5 text-sm font-medium text-gray-900">Data Peserta</p>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="name"
          id="name"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          required
          placeholder=" "
          autoComplete="off"
          value={values?.name || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="name"
          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Nama Peserta
        </label>
      </div>
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="place_of_birth"
            id="place_of_birth"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            autoComplete="off"
            value={values?.place_of_birth || ""}
            onChange={handleChange}
          />
          <label
            htmlFor="place_of_birth"
            className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Tempat Lahir
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="date"
            name="date_of_birth"
            id="date_of_birth"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            autoComplete="off"
            value={values?.date_of_birth || ""}
            onChange={handleChange}
          />
          <label
            htmlFor="date_of_birth"
            className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Tanggal Lahir
          </label>
        </div>
      </div>
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="city"
            id="city"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            autoComplete="off"
            value={values?.city || ""}
            onChange={handleChange}
          />
          <label
            htmlFor="city"
            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Asal Kota
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="choir_name"
            id="choir_name"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            autoComplete="off"
            value={values?.choir_name || ""}
            onChange={handleChange}
          />
          <label
            htmlFor="choir_name"
            className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Nama Choir
          </label>
        </div>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <textarea
          rows={2}
          name="address"
          id="address"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          autoComplete="off"
          value={values?.address || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="address"
          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Alamat Peserta
        </label>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="phone"
          name="contact"
          id="contact"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          autoComplete="off"
          value={values?.contact || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="contact"
          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          No.HP/WA
        </label>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="email"
          name="email"
          id="email"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          autoComplete="off"
          value={values?.email || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="email"
          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Email address
        </label>
      </div>
    </div>
  );
}
