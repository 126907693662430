import { useState } from "react";
import { Link, NavLink, useLocation, useSubmit } from "react-router-dom";

// import Logo from "../logo.jpeg";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Header({
  navs,
  user,
  show,
  onChange,
}: // authenticated,
any) {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const submit = useSubmit();
  return (
    <header className="relative bg-black">
      <nav>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <div className="flex gap-4">
            <img src={"./logo.jpeg"} className="h-8" alt="Logo" />
            <span className="self-center text-left text-sm sm:text-2xl font-semibold whitespace-nowrap text-gold uppercase">
              PCE
            </span>
          </div>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
            {user ? (
              <div className="relative">
                <button
                  data-dropdown-toggle="dropdown-project"
                  onClick={() => onChange(true)}
                  onMouseOver={() => onChange(true)}
                >
                  <svg
                    className="w-6 h-6 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </button>
                <div
                  id="navbar-dropdown"
                  onMouseLeave={() => onChange(false)}
                  className={`${
                    show ? "" : "hidden"
                  } absolute z-10 top-19.5 right-0.5  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 `}
                >
                  <div className="my-4 text-center text-xs justify-center">
                    <div className="mx-auto relative w-10 h-10 mb-2 overflow-hidden bg-gray-100 rounded-full ">
                      <svg
                        className="absolute w-12 h-12 text-gray-400 -left-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    {user?.split("@")[0]}
                  </div>
                  <ul
                    className="py-2 text-sm text-gray-700 "
                    aria-labelledby="avatarButton"
                  >
                    <li>
                      <NavLink
                        to="/admin"
                        className="block px-4 py-2 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Peserta Kompetisi
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/admin/workshop"
                        className="block px-4 py-2 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Peserta Workshop
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/admin/inbox"
                        className="block px-4 py-2 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Daftar Kontak
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to="/admin/tiket"
                        className="block px-4 py-2 hover:bg-gray-100 "
                        role="menuitem"
                      >
                        My Ticket
                      </NavLink>
                    </li> */}
                  </ul>
                  <div className="py-1 w-auto">
                    <NavLink
                      to="/ubah-password"
                      className="block px-4 py-2 hover:bg-gray-100 text-sm text-gray-700 "
                      role="menuitem"
                    >
                      Ubah Password
                    </NavLink>
                  </div>
                  <div className="py-1 w-auto">
                    <button
                      type="button"
                      role="menuitem"
                      // onClick={() => onChange(false)}
                      onFocus={() =>
                        submit(null, {
                          method: "post",
                          encType: "application/json",
                          action: "/logout",
                        })
                      }
                      className="block px-4 py-2 text-sm text-left text-red-500 hover:bg-gray-100 w-full"
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <Link
                type="button"
                to="/login"
                className="text-white  focus:text-blue-300 focus:outline-none rounded-lg text-sm font-light px-4 py-2 text-center "
              >
                Login
              </Link>
            )}

            <button
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              aria-controls="navbar-default"
              aria-expanded="false"
              onClick={() => setShowMenu(!showMenu)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`${
              showMenu ? "block" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
              {navs.map((nav: any, idx: any) => (
                <li
                  key={idx}
                  className={`${
                    pathname === nav.url ? "active" : ""
                  }  active::text-blue-500`}
                >
                  <Link
                    to={nav.url}
                    className="block py-2 pr-4 pl-3 text-gray-300 hover:text-white rounded lg:p-0"
                    aria-current="page"
                    unstable_viewTransition
                  >
                    {nav.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
