import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="relative p-4 bg-black sm:p-6 w-full">
      <div className="mx-auto max-w-screen-xl">
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-orange-100 font-light sm:text-center">
            © 2024{" "}
            <Link to="#" className="hover:underline">
              PCE2024.org
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}
