import React, { useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLoaderData, useSearchParams } from "react-router-dom";

const InboxPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const loader: any = useLoaderData();
  const navList = [
    { label: "Beranda", url: "/" },
    { label: "Workshop", url: "/class" },
    { label: "Jadwal", url: "/jadwal" },
    { label: "Juri", url: "/juri" },
    { label: "Kontak", url: "/kontak" },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePagiantionNav = (e: any) => {
    const { target } = e;
    const operation = target.getAttribute("data-nav-operation");
    const offset = operation === "next" ? 1 : -1;
    const params = new URLSearchParams();
    const currentPage = Number(searchParams.get("page")?.toString()) || 1;
    params.set(
      "page",
      currentPage + offset === 0 ? "1" : (currentPage + offset).toString()
    );
    setSearchParams(params, {
      preventScrollReset: true,
    });
  };

  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-black opacity-60"></div>
      <Header
        navs={navList}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="bg-black bg-cover h-screen">
        <div className="relative py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-white">
            Inbox
          </h2>
          <table className="table-auto w-full text-sm text-left rtl:text-right text-white bg-black border-white border">
            <thead className="text-xs text-gray-800 uppercase border-b bg-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Subject
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Pesan
                </th>
              </tr>
            </thead>
            <tbody>
              {loader.data.map((dt: any, idx: number) => (
                <tr className=" text-white border-b" key={idx}>
                  <td className="px-6 py-4">{dt.subject}</td>
                  <td className="px-6 py-4">{dt.email}</td>
                  <td className="px-6 py-4">{dt.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                // onClick={onActionClick}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                data-nav-operation="previous"
                disabled={parseInt(searchParams.get("page") || "0") < 2}
              >
                Sebelumnya
              </button>
              <button
                // disabled={disableNext}
                // onClick={onActionClick}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                data-nav-operation="next"
              >
                Selanjutnya
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Menampilkan{" "}
                  <span className="font-medium">
                    {(loader?.count === 0
                      ? 0
                      : parseInt(searchParams.get("page") || "0") <= 1
                      ? 1
                      : (parseInt(searchParams.get("page") || "0") - 1) * 10 + 1
                    )
                      .toLocaleString()
                      .replace(",", ".")}
                  </span>{" "}
                  hingga{" "}
                  <span className="font-medium">
                    {loader?.count === 0
                      ? 0
                      : loader?.count <= 10
                      ? loader?.count
                      : (parseInt(searchParams.get("page") || "0") <= 1
                          ? 10
                          : parseInt(searchParams.get("page") || "0") * 10
                        )
                          .toLocaleString()
                          .replace(",", ".")}
                  </span>{" "}
                  dari{" "}
                  <span className="font-medium">
                    {Number(loader?.count).toLocaleString().replace(",", ".") ||
                      0}
                  </span>{" "}
                  data
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={handlePagiantionNav}
                    disabled={parseInt(searchParams.get("page") || "0") < 2}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sebelumnya</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                      data-nav-operation="previous"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>

                    {/* <ChevronLeftIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                      data-nav-operation="previous"
                    /> */}
                  </button>

                  <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                    ...
                  </span>

                  <button
                    type="button"
                    disabled={
                      (Number(searchParams.get("page")?.toString()) || 1) *
                        10 >=
                      loader?.count
                    }
                    onClick={handlePagiantionNav}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Selanjutnya</span>
                    <svg
                      data-nav-operation="next"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default InboxPage;
