import { AxiosResponse } from "axios";

import httpCommon from "./http-common";
import { TInbox } from "../types/TInbox";

export const getDatatables = async (page: number) => {
  const response: AxiosResponse = await httpCommon.get(`/inbox?page=${page}`);
  return response.data as TInbox[];
};

export const createInbox = async (data: TInbox) => {
  try {
    return await httpCommon.post("/inbox", data);
  } catch (error: any) {
    return error?.response;
  }
};

export const updatePic = async (data: TInbox) => {
  try {
    return await httpCommon.put<TInbox>("/inbox/" + data.id, data);
  } catch (error: any) {
    return error?.response;
  }
};

export const deletePic = async (id: string) => {
  try {
    return await httpCommon.delete<TInbox>("/inbox/" + id);
  } catch (error: any) {
    return error?.response;
  }
};
