import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";
import { getDatatables } from "../actions/choir.action";
import { getUser } from "../services/localStorage";

export async function adminLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  const page = new URL(request.url).searchParams.get("page");
  const currentPage = Number(page) || 1;
  const pics: any = await getDatatables(currentPage);
  return json({
    authenticated: isAuthenticated(),
    user: getUser(),
    data: pics?.data,
    count: pics?.count,
  });
}
