import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from "../components/Stepper";

const TicketPage: React.FC = () => {
  const [show, setShow] = useState(false);
  const loader: any = useLoaderData();
  const navList = [
    { label: "Beranda", url: "/" },
    { label: "Workshop", url: "/class" },
    { label: "Jadwal", url: "/jadwal" },
    { label: "Juri", url: "/juri" },
    { label: "Kontak", url: "/kontak" },
  ];
  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-black opacity-60"></div>
      <Header
        navs={navList}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="bg-gray-500 bg-cover h-screen">
        <div className="relative py-10 lg:py-16 px-4 mx-auto max-w-screen-md space-y-3">
          <h2 className="text-4xl tracking-tight font-extrabold text-center text-white">
            My Ticket
          </h2>
          <div className="pt-4 space-y-2">
            <Stepper {...loader} />
            {/* <ol className="relative  border-s border-gray-200 dark:border-gray-700 text-gray-400">
              <li className="mb-10 ms-6 space-x-2">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg
                    className="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                </span>
                <h3 className="font-medium leading-tight">PIC Account</h3>
                <p className="text-xs text-gray-300">Step details here</p>
              </li>
              <li className="mb-10 ms-6 space-x-2">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg
                    className="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                </span>
                <h3 className="font-medium leading-tight">Verifikasi Email</h3>
                <p className="text-xs text-gray-300">{loader.user}</p>
              </li>
              <li className="mb-10 ms-6 space-x-2 space-y-2">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                  <svg
                    className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                  </svg>
                </span>
                <h3 className="font-medium leading-tight">Upload Peserta</h3>
                <Form
                  className="max-w-lg mx-auto space-y-2"
                  method="POST"
                  encType="multipart/form-data"
                  action={"/admin/tiket/" + loader?.user}
                >
                  <div className="flex gap-4">
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="user_avatar_help"
                      id="user_avatar"
                      type="file"
                accept=".pdf"
                      name="file"
                    />
                    <button
                      type="submit"
                      className="bg-blue-400 rounded-lg py-1 px-4 text-white"
                    >
                      Upload
                    </button>
                  </div>
                  <p className="flex text-xs text-gray-300 gap-2 items-center">
                    Draft data peserta (.xls).
                    <Link
                      to="/files/Form Registrasi Peserta PCE 2024.xlsx"
                      target="_blank"
                      download
                      className="text-blue-500 flex gap-1 items-center"
                    >
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
                        />
                      </svg>
                      Download
                    </Link>
                  </p>
                </Form>
              </li>
              <li className="mb-10 ms-6 space-x-2">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                  <svg
                    className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                  </svg>
                </span>
                <h3 className="font-medium leading-tight">Billing</h3>
                <p className="text-xs text-yellow-300 ">
                  Menunggu verifikasi data
                </p>
              </li>
              <li className="ms-6 space-x-2">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                  <svg
                    className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z" />
                  </svg>
                </span>
                <h3 className="font-medium leading-tight">Confirmation</h3>
                <p className="text-xs text-yellow-300 ">
                  Menunggu verifikasi data
                </p>
              </li>
            </ol> */}
          </div>

          {/* <Form className="space-y-8" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-300"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border  text-white text-sm rounded-lg focus:border-primary-500 block w-full p-2.5 shadow-sm-light"
                placeholder="name@mail.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-300"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="shadow-sm bg-gray-50 border  text-white text-sm rounded-lg focus:border-primary-500 block w-full p-2.5 shadow-sm-light"
                placeholder="Apa yang bisa kami bantu?"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-400"
              >
                Pesan
              </label>
              <textarea
                id="message"
                rows={6}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Pesan..."
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit hover:bg-primary-800 focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-primary-800"
            >
              Kirim pesan
            </button>
          </Form>
          <form className="max-w-lg mx-auto">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="user_avatar"
            >
              Upload file
            </label>
            <input
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="user_avatar_help"
              id="user_avatar"
              type="file"
              accept=".pdf"
            />
            <div
              className="mt-1 text-sm text-gray-500 dark:text-gray-300"
              id="user_avatar_help"
            >
              A profile picture is useful to confirm your are logged into your
              account
            </div>
          </form> */}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TicketPage;
