import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";
import { getUser } from "../services/localStorage";

export function protectedLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return json({ authenticated: isAuthenticated(), user: getUser() });
}
