import { LoaderFunctionArgs, json } from "react-router-dom";

import * as AuthAction from "./auth.action";

export async function forgotPasswordAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());
  data.id =
    data.id === undefined || data.id === "undefined" ? undefined : data.id;
  const response: any = await AuthAction.forgotPassword(data);
  return json({ ...response });
}
