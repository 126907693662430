import { useEffect, useState } from "react";

interface IProps {
  open: boolean;
  filename?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

export default function PreviewModal(props: IProps) {
  const [_file, setFile] = useState<string>(props?.filename || "");
  useEffect(() => {
    props.filename && setFile(props.filename);
  }, [props.filename]);
  return (
    <div
      id="default-modal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.open ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center w-screen md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
      <div className="relative p-4 w-full h-64 max-w-5xl max-h-fit m-auto">
        <div className="relative bg-white rounded-lg shadow ">
          {_file && (
            <iframe
              className="w-full h-[500px]"
              src={`//API.PCE2024.ORG/choir/preview/${_file}`}
              title="GeeksforGeeks"
            ></iframe>
          )}

          <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b ">
            <button
              data-modal-hide="default-modal"
              type="button"
              onClick={props.onCancel}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              Selesai
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
