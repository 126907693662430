import { LoaderFunctionArgs, json } from "react-router-dom";

import { createInbox } from "./inbox.action";

export async function contactAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());
  data.id =
    data.id === undefined || data.id === "undefined" ? undefined : data.id;
  const response: any = await createInbox(data);
  return json({ ...response });
}
