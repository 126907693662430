import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { AxiosResponse } from "axios";

import httpCommon from "../actions/http-common";
import { isAuthenticated } from "../services/authenticationService";
import { getUser } from "../services/localStorage";

export async function workshopLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  const page = new URL(request.url).searchParams.get("page");
  const currentPage = Number(page) || 1;
  const pics: any = await getDatatables(currentPage);
  return json({
    authenticated: isAuthenticated(),
    user: getUser(),
    data: pics?.data,
    count: pics?.count,
  });
}

export const getDatatables = async (page: number) => {
  const response: AxiosResponse = await httpCommon.get(
    `/workshop?page=${page}`
  );
  return response.data as any[];
};
