export const classs = [
  {
    label: "Mixed Choir",
    description: "Usia minimal 17 tahun per September 2024.",
  },
  {
    label: "Equal Choir",
    description: "Usia minimal 17 tahun per September 2024.",
  },
  {
    label: "Children Choir",
    description: "Usia 6-13 tahun per September 2024.",
  },
  {
    label: "Youth Choir",
    description: "Usia 14-20 tahun per September 2024.",
  },
  {
    label: "Folklore",
    description:
      "Membawakan Lagu Folksong . Usia minimal 17 tahun per September.",
  },
];
