import { LoaderFunctionArgs, json } from "react-router-dom";

import httpCommon from "./http-common";

export async function adminAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());
  console.log(data);
  data.id =
    data.id === undefined || data.id === "undefined" ? undefined : data.id;
  data.validate =
    data.validate === undefined || data.validate === "false" ? false : true;
  const response = await updateWorkshop(data);
  return json({ ...response });
}

export const updateWorkshop = async (data: any) => {
  try {
    // return await httpCommon.put<any>("/auth/refreshtoken" + data.id, data);
    return await httpCommon.put("/choir/" + data.id, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error: any) {
    return error?.response;
  }
};
