import { Link, useLoaderData } from "react-router-dom";

interface IProps {
  show: boolean;
  values: any;
  errors?: any;
  onChange: (e: any) => void;
}

export default function TermCard({ onChange, show, values, errors }: IProps) {
  const loader: any = useLoaderData();

  const handleChange = (e: any) => {
    onChange({
      ...values,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <div className={show ? "block" : "hidden"}>
      <p className="mb-2 text-sm font-medium text-gray-900">Persyaratan</p>
      <ul className="space-y-2 text-white list-inside mx-auto mb-5 text-sm font-light">
        {loader?.rules.map((rule: string, idx: number) => (
          <li className="flex items-center text-gray-600" key={idx}>
            <svg
              className="w-3.5 h-3.5 me-2 text-green-500  flex-shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            {rule}
          </li>
        ))}
      </ul>
      <p className="mb-5 text-sm font-medium text-gray-900">
        File yang perlu di persiapkan untuk di upload saat pendaftaran,
        diantaranya :
      </p>
      <ul className="space-y-1 pl-3 list-disc mx-auto mb-5 text-sm font-light">
        <li className="flex gap-2">
          Data Excel peserta choir{" "}
          <Link
            to="/files/Form Registrasi Peserta PCE 2024.xlsx"
            target="_blank"
            download
            className="text-blue-500 flex gap-1 items-center"
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
              />
            </svg>
            Download Template
          </Link>
        </li>
        <li className="flex">Photo Choir</li>
        <li className="flex"> Logo Choir</li>
        <li className="flex">Biography Choir</li>
        <li className="flex">
          Scan Kartu Identitas Peserta (Dijadikan satu PDF)
        </li>
        <li className="flex"> Bukti Pembayaran</li>
      </ul>
      <div className="divide-y divide-gray-400 hover:divide-y-8"> </div>{" "}
      <div className="flex items-center">
        <input
          id="link-checkbox"
          type="checkbox"
          name="policy"
          value={values?.policy}
          onChange={handleChange}
          className={`text-blue-600 bg-gray-100 border-gray-300 w-4 h-4 rounded focus:ring-blue-500  focus:ring-2 `}
        />
        <label
          htmlFor="link-checkbox"
          className={`${
            errors.policy ? "text-red-600  " : "text-gray-600 "
          } ms-2 text-sm font-sm `}
        >
          Saya menyetujui <b>Syarat dan Ketentuan persyaratan</b>.
        </label>
      </div>
      <p
        className={`${
          errors.policy ? "block" : "hidden"
        } mt-2 text-sm text-red-600 dark:text-red-500`}
      >
        {errors?.policy}
      </p>
    </div>
  );
}
