export const rules = [
  "Peserta Choir berjumlah 24-32 Penyanyi + 1 Dirigen (sudah termasuk pemusik)",
  "Membawakan 2 lagu yang dipilih oleh peserta secara bebas",
  "Lagu dibawakan secara Acapella atau menggunakan iringan",
  "Boleh menggunakan iringan secara akustik. Panitia hanya menyediakan piano",
  "Durasi tampil maksimal 10 menit",
  "Wajib menyerahkan repertoire (beserta surat legalitas pengaransemen, kecuali lagu yang ada dalam buku yang sudah diterbitkan) kepada panitia pada saat Technical Meeting yaitu tanggal 14 Juli 2024",
  "Peserta dilarang membawa ornamen/properti/atribut yang dapat membuat kotor panggung",
  "Membayar biaya pendaftaran sebesar Rp. 2,000,000.",
];
