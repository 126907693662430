import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import JudgePage from "./pages/JudgePage";
import HomePage from "./pages/HomePage";
import ClassPage from "./pages/ClassPage";
import SchedulePage from "./pages/SchedulePage";
import ContactPage from "./pages/ContactPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { loginLoader } from "./loaders/loginLoader";
import { loginAction } from "./actions/login.action";
import { forgotPasswordAction } from "./actions/forgotPassword.action";
import { forgotPasswordLoader } from "./loaders/forgotPasswordLoader";
import { registerAction } from "./actions/register.action";
import { registerLoader } from "./loaders/registerLoader";
import { protectedLoader } from "./loaders/protectedLoader";
import { landingLoader } from "./loaders/landingLoader";
import TicketPage from "./pages/TicketPage";
import AdminPage from "./pages/AdminPage";
import InboxPage from "./pages/InboxPage";
import { useAppDispatch } from "./reducers/hooks";
import { logout } from "./slices/loginSlice";
import { contactAction } from "./actions/contact.action";
import { ticketAction } from "./actions/ticket.action";
import { adminLoader } from "./loaders/adminLoader";
import { inboxLoader } from "./loaders/inboxLoader";
import { classAction } from "./actions/class.action";
import WorkshopPage from "./pages/WorkshopPage";
import { workshopLoader } from "./loaders/workshopLoader";
import { workshopAction } from "./actions/workshop.action";
import { homeAction } from "./actions/home.action";
import { adminAction } from "./actions/admin.action";
import ChangesPassword from "./pages/ChangesPassword";
import { changePasswordAction } from "./actions/changePassword.action";

function App() {
  const dispatch = useAppDispatch();
  const router = createBrowserRouter([
    {
      path: "/",
      loader: landingLoader,
      action: homeAction,
      element: <HomePage />,
    },
    {
      path: "/juri",
      loader: landingLoader,
      element: <JudgePage />,
    },
    {
      path: "/class",
      loader: landingLoader,
      action: classAction,
      element: <ClassPage />,
    },
    {
      path: "/jadwal",
      loader: landingLoader,
      element: <SchedulePage />,
    },
    {
      path: "/kontak",
      loader: landingLoader,
      action: contactAction,
      element: <ContactPage />,
    },
    {
      path: "/admin",
      loader: adminLoader,
      action: adminAction,
      element: <AdminPage />,
    },
    {
      path: "/admin/workshop",
      loader: workshopLoader,
      action: workshopAction,
      element: <WorkshopPage />,
    },
    {
      path: "/admin/inbox",
      loader: inboxLoader,
      element: <InboxPage />,
    },
    {
      path: "/admin/tiket/:email",
      action: ticketAction,
    },
    {
      path: "/admin/tiket",
      loader: protectedLoader,
      element: <TicketPage />,
    },
    {
      path: "/ubah-password",
      loader: protectedLoader,
      action: changePasswordAction,
      element: <ChangesPassword />,
    },
    {
      path: "/lupa-password",
      loader: forgotPasswordLoader,
      action: forgotPasswordAction,
      element: <ForgotPasswordPage />,
    },
    {
      path: "/register",
      loader: registerLoader,
      action: registerAction,
      element: <RegisterPage />,
    },
    {
      path: "/login",
      loader: loginLoader,
      action: loginAction,
      element: <LoginPage />,
    },
    {
      path: "/logout",
      async action() {
        await dispatch(logout());
        return redirect("/");
      },
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
