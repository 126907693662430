import { LoaderFunctionArgs, json } from "react-router-dom";

import httpCommon from "./http-common";
import { getUser } from "../services/localStorage";

export async function changePasswordAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());
  const errors = validate(data);

  if (errors) {
    return json({ errors }, { status: 422 });
  }
  console.log(data);
  const response = await updatePassword(data);
  return json({ ...response }, { status: 200 });
}

export function validate(data: any) {
  const errors: any = {};

  if (!data.old_password) {
    errors.old_password = "Password lama belum di input.";
  }

  if (!data.new_password) {
    errors.new_password = "Password baru belum di input.";
  }

  if (!data.reNew_password) {
    errors.reNew_password = "Ketik ulang Password baru belum di input.";
  }

  if (data.old_password === data.new_password) {
    errors.new_password =
      "Password baru tidak boleh sama dengan password lama.";
  }

  if (data.new_password !== data.reNew_password) {
    errors.reNew_password = "Ketik ulang Password baru berbeda.";
  }

  if (Object.keys(errors).length) {
    return errors;
  }
}

export const updatePassword = async (data: any) => {
  try {
    // return await httpCommon.put<any>("/auth/refreshtoken" + data.id, data);
    return await httpCommon.put("/user/change-password/" + getUser(), data);
  } catch (error: any) {
    return error?.response;
  }
};
