export const setTokens = (authRes: any) => {
  localStorage.setItem("username", authRes.username);
  localStorage.setItem("access_token", authRes.accessToken);
  localStorage.setItem("refresh_token", authRes.refreshToken);
  // localStorage.setItem("role", authRes.roles[0]);
};

export const removeTokens = () => {
  localStorage.removeItem("username");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  // localStorage.removeItem("role");
};
export const getAccessToken = () =>
  localStorage.getItem("access_token") === "undefined"
    ? 0
    : localStorage.getItem("access_token")?.toString().slice(1, -1);
export const getRole = () => localStorage.getItem("role")?.toString();
export const getUser = () => localStorage.getItem("username");
export const setUser = (user: any) =>
  localStorage.setItem("username", JSON.stringify(user));
export const getRefreshToken = () => localStorage.getItem("refresh_token");
