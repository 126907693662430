import { useEffect, useState } from "react";
import BillingCard from "../choir/BillingCard";
import { Form } from "react-router-dom";
import WorkshopDetailsCard from "../workshop/WorkshopDetailsCard";

interface IProps {
  open: boolean;
  data?: any;
  currentForm: number;
  onOk?: () => void;
  onCancel?: () => void;
}

export default function DetailMemberWorkshopEditModal({
  open,
  data,
  currentForm,
  onOk,
  onCancel,
}: IProps) {
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <>
      <div
        id="default-modal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          open ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
        <div className="relative p-4 w-fit h-full max-w-2xl max-h-full m-auto">
          <div className="relative bg-white rounded-lg shadow">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
              <h3 className="text-xl font-semibold text-gray-900 ">
                Data Peserta
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                data-modal-hide="default-modal"
                onClick={onCancel}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <Form
              method="PUT"
              encType="multipart/form-data"
              action="/admin/workshop"
            >
              <div className="px-2 md:px-4 space-y-4 text-sm font-normal">
                <input type="hidden" name="id" value={formData?.id} />
                {currentForm === 0 && (
                  <WorkshopDetailsCard
                    show={currentForm === 0}
                    values={formData}
                    onChange={setFormData}
                  />
                )}
                {currentForm === 1 && (
                  <BillingCard
                    show={currentForm === 1}
                    values={formData}
                    onChange={setFormData}
                  />
                )}
              </div>
              <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b ">
                <>
                  <button
                    data-modal-hide="default-modal"
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Simpan
                  </button>
                  <button
                    data-modal-hide="default-modal"
                    type="button"
                    onClick={onCancel}
                    className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
                  >
                    Selesai
                  </button>{" "}
                </>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
