import { AxiosResponse } from "axios";
import { TPic } from "../types/TPic";
import httpCommon from "./http-common";

export const getDatatables = async (page: number) => {
  try {
    const response: AxiosResponse = await httpCommon.get(`/choir?page=${page}`);
    return response.data as any[];
  } catch (error: any) {
    return error?.response;
  }
};

export const createChoir = async (data: TPic) => {
  try {
    return await httpCommon.post("/choir", data);
  } catch (error: any) {
    return error?.response;
  }
};

export const updateChoir = async (data: TPic) => {
  try {
    return await httpCommon.put<any>("/choir/" + data.id, data);
  } catch (error: any) {
    return error?.response;
  }
};

export const deleteChoir = async (id: string) => {
  try {
    return await httpCommon.delete<any>("/choir/" + id);
  } catch (error: any) {
    return error?.response;
  }
};
