interface IProps {
  title: string;
  validasi: boolean;
  onClick?: () => void;
}

export const Divider = ({ title, validasi = false, onClick }: IProps) => {
  return (
    <div className="relative flex items-center">
      <span className="flex-shrink mx-4 text-gray-400">{title}</span>
      <div className="flex-grow border-t border-gray-400"></div>
      <span className="flex-shrink mx-4 text-gray-400">
        <button onClick={onClick} className={validasi ? "hidden" : "block"}>
          <svg
            className="w-4 h-4 text-blue-800 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
            />
          </svg>
        </button>
      </span>
    </div>
  );
};
