import React, { useState } from "react";

import Judge1 from "../assets/images/Flora Monica Gozali (Lala Gozali).jpg";
import Judge2 from "../assets/images/WhatsApp Image 2024-05-02 at 14.38.45.jpeg";
import Judge3 from "../assets/images/Omid Maheb.png";
import Judge4 from "../assets/images/Yustinus Roni Sugiarto.jpg";
import Judge5 from "../assets/images/98eacd63-5e1a-43f2-9118-ac2709925344.jpeg";
import Judge6 from "../assets/images/Agastya Rama Listya.jpeg";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLoaderData } from "react-router-dom";

const JudgePage: React.FC = () => {
  const [show, setShow] = useState(false);
  const loader: any = useLoaderData();
  const navList = [
    { label: "Beranda", url: "/" },
    { label: "Workshop", url: "/class" },
    { label: "Jadwal", url: "/jadwal" },
    // { label: "Register", url: "/register" },
    { label: "Juri", url: "/juri" },
    { label: "Kontak", url: "/kontak" },
  ];
  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-black opacity-60"></div>
      <Header
        navs={navList}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="sm:h-screen bg-class bg-cover">
        <div className="relative py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
              Para Juri
            </h2>
            {/* <p className="font-light text-gray-400 sm:text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
          </div>
          <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div className="text-center text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover hover:object-scale-down object-left-top"
                src={Judge1}
                alt="Lala Gozali"
              />
              <h4 className="mb-1 text-2xl font-normal tracking-tight text-white">
                Lala Gozali
              </h4>
            </div>
            <div className="text-center text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover hover:object-scale-down object-right-top"
                src={Judge5}
                alt="Lala Gozali"
              />
              <h4 className="mb-1 text-2xl font-normal tracking-tight text-white">
                Albertus Wihananta
              </h4>
            </div>
            <div className="text-center text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover object-left-top"
                src={Judge2}
                alt="Helene Avatar"
              />
              <h4 className="mb-1 text-2xl font-normal tracking-tight text-white">
                Gregorius Gerald Pratomo, M.Mus
              </h4>
            </div>
            <div className="text-center text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover object-left-top"
                src={Judge3}
                alt="Jese Avatar"
              />
              <h4 className="mb-1 text-2xl font-normal tracking-tight text-white">
                Omid Moheb-Zadeh, M.Mus
              </h4>
            </div>
            <div className="text-center text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src={Judge4}
                alt="Joseph Avatar"
              />
              <h4 className="mb-1 text-2xl font-normal tracking-tight text-white">
                Yustinus Roni Sugiarto
              </h4>
            </div>
            <div className="text-center text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src={Judge6}
                alt="Joseph Avatar"
              />
              <h4 className="mb-1 text-2xl font-normal tracking-tight text-white">
                Agastya Rama Listya, MSM, Ph.D
              </h4>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default JudgePage;
