import { AxiosResponse } from "axios";
import { TPic } from "../types/TPic";
import httpCommon from "./http-common";
// import { LoaderFunctionArgs, redirect } from "react-router-dom";

// export async function picAction({ request }: LoaderFunctionArgs) {
//   const data: any = Object.fromEntries(await request.formData());

//   if (data?.action === "delete") {
//     await deletePic(data?.id);
//   } else {
//     data.id =
//       data.id === undefined || data.id === "undefined" ? undefined : data.id;
//     data.id === undefined || data.id === "undefined"
//       ? await createPic(data)
//       : await updatePic(data);
//   }

//   return redirect("/register");
// }

export const getDatatables = async () => {
  try {
    const response: AxiosResponse = await httpCommon.get("/pic");
    return response.data as TPic[];
  } catch (error: any) {
    return error?.response;
  }
};

export const createPic = async (data: TPic) => {
  try {
    return await httpCommon.post("/pic", data);
  } catch (error: any) {
    return error?.response;
  }
};

export const updatePic = async (data: TPic) => {
  try {
    return await httpCommon.put<TPic>("/pic/" + data.id, data);
  } catch (error: any) {
    return error?.response;
  }
};

export const deletePic = async (id: string) => {
  try {
    return await httpCommon.delete<TPic>("/pic/" + id);
  } catch (error: any) {
    return error?.response;
  }
};
