import { json } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";
import { getUser } from "../services/localStorage";
import { rules } from "../data/rules.data";
import { classs } from "../data/classs.data";
import { navs } from "../data/navs.data";

export async function landingLoader() {
  return json({
    authenticated: isAuthenticated(),
    user: getUser(),
    navs,
    rules,
    classs,
  });
}
