import React, { useCallback, useEffect, useRef, useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from "react-router-dom";

const ClassPage: React.FC = () => {
  const { state } = useNavigation();
  const loader: any = useLoaderData();
  const actionRes: any = useActionData();

  const submit = useSubmit();

  const [show, setShow] = useState(false);
  const [currentForm, setCurrentForm] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [learns, setLearns] = useState<string[]>([]);
  const [dataForm, setDataForm] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (actionRes?.status === 201) setCurrentForm(2);
    // setShowModal(false);
  }, [actionRes]);

  const formRef = useRef<any>(null);

  const [totalPayment, setTotalPayment] = useState(0);

  const navList = [
    { label: "Beranda", url: "/" },
    { label: "Workshop", url: "/class" },
    { label: "Jadwal", url: "/jadwal" },
    { label: "Juri", url: "/juri" },
    { label: "Kontak", url: "/kontak" },
  ];
  const classs: any[] = [
    {
      label: "VOCAL CLASS OLEH RONI SUGIARTO",
      description: [
        // "Tiap Choir mengirimkan 4 orang perwakilan (SATB).",
        "Biaya Rp. 250.000/orang.",
      ],
      price: 250000,
    },
    {
      label: "CONDUCTING CLASS OLEH OMID MOHEB-ZADEH, M. MUS",
      description: [
        // "Tiap Choir mengirimkan 1 orang atau lebih perwakilan.",
        // "Bolen berpartisipasi secara individu (tidak termasuk dalam Choir).",
        "Biaya Rp. 250.000/orang.",
      ],
      price: 250000,
    },
    {
      label: "GREGORIAN CLASS OLEH MR. GREGORIUS GERALD PRATOMO, M. MUS",
      description: [
        // "Tiap Choir mengirimkan 1 orang atau lebih perwakilan.",
        // "Boleh berpartisipasi secara individu (tidak termasuk dalam Choir).",
        "Biaya Rp. 250.000/orang.",
      ],
      price: 250000,
    },
    {
      label:
        "KAJIAN REPERTOAR DAN ARANSEMEN OLEH AGASTYA RAMA LISTYA, MSM. PH. D",
      description: [
        // "Tiap Choir mengirimkan 1 orang atau lebih perwakilan.",
        // "Boleh berpartisipasi secara individu (tidak termasuk dalam Choir).",
        "Biaya Rp. 250.000/orang.",
      ],
      price: 250000,
    },
    // {
    //   label: "ARRANGEMENT CLASS WITH MRS. FLORA MONIKA GOZALI",
    //   description: [
    //     "Tiap Choir mengirimkan 1 orang atau lebih perwakilan.",
    //     "Boleh berpartisipasi secara individu (tidak termasuk dalam Choir).",
    //     "Biaya Rp. 200.000/orang.",
    //   ],
    //   price: 200000,
    // },
  ];

  const handleChangesLearn = useCallback(
    (title: string) => {
      learns.includes(title)
        ? setLearns(() => [...learns.filter((tl: any) => tl !== title)])
        : setLearns((prev) => [...prev, title]);
      if (learns.length > 0) delete errors.learns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [learns]
  );

  useEffect(() => {
    let pay = 0;
    learns.map(
      (lnr) =>
        (pay += classs.filter(
          (dt: any) => dt.label === lnr.replaceAll("_", " ")
        )[0]?.price)
    );
    setTotalPayment(pay);
    setDataForm((prev: any) => ({ ...prev, workshop: learns }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learns]);

  const handleBtnSubmit = () => {
    const _errors: any = {};
    if (currentForm === 0) {
      if (!dataForm.name) {
        _errors.name = "Nama belum di input.";
      }

      if (!dataForm.place_of_birth) {
        _errors.place_of_birth = "Tempat lahir belum di input.";
      }

      if (!dataForm.date_of_birth) {
        _errors.date_of_birth = "Tanggal lahir belum di input.";
      }

      if (!dataForm.city) {
        _errors.city = "Kota belum di input.";
      }

      if (!dataForm.address) {
        _errors.address = "Alamat belum di input.";
      }

      if (!dataForm.contact) {
        _errors.contact = "Kontak belum di input.";
      }

      if (!dataForm.email) {
        _errors.email = "Email belum di input.";
      }

      if (learns.length === 0) {
        _errors.learns = "Workshop belum di pilih.";
      }

      if (Object.keys(_errors).length) {
        setErrors({
          ..._errors,
        });
      } else {
        setCurrentForm(1);
      }
    } else {
      if (!dataForm.bank) {
        _errors.bank = "Bank belum di input.";
      }

      if (!dataForm.owner_rek) {
        _errors.owner_rek = "Pemilik rekening belum di input.";
      }

      if (!dataForm.file_bill) {
        _errors.file_bill = "File belum di upload.";
      }
      if (Object.keys(_errors).length) {
        setErrors({
          ..._errors,
        });
      } else {
        submit(formRef.current);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleChange = (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    if (e.target.value !== "" && errors[e.target.name])
      delete errors[e.target.name];
  };

  const handleFile = (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.files[0] });
    if (e.target.value !== "" && errors[e.target.name])
      delete errors[e.target.name];
  };

  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-black opacity-60"></div>
      <Header
        navs={navList}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="bg-class bg-cover md:min-h-screen">
        <div className="fixed w-full h-full bg-gradient-to-t from-black object-cover mix-blend-multiply"></div>
        <div className="relative py-8 lg:py-16 mx-auto max-w-screen-xl px-4 text-orange-300">
          <div className="mb-8 max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold leading-tight tracking-tight text-white">
              Workshop
            </h2>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            {classs.map((dt: any, idx: number) => (
              <div
                key={idx}
                className="relative bg-gray-200 p-4 rounded-lg space-y-2"
              >
                <h3 className="mb-4 text-lg font-bold text-black">
                  {dt.label}
                </h3>
                <ul className="list-disc list-outside pl-4 text-gray-600 text-sm">
                  {dt.description.map((text: any, descIdx: number) => (
                    <li key={descIdx}>{text}</li>
                  ))}
                </ul>
                <button
                  className="bg-blue-500 hover:bg-blue-600 rounded-lg py-1 px-4 text-white"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Daftar workshop
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
      <div
        id="default-modal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          showModal ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
        <div className="relative p-4 w-full max-w-2xl max-h-full  mx-auto">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between px-4 py-2 md:p-5 border-b rounded-t ">
              <h3 className="text-xl font-semibold text-gray-900 ">
                Pendaftaran Workshop
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                data-modal-hide="default-modal"
                onClick={() => setShowModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <Form
              method="POST"
              encType="multipart/form-data"
              action="/class"
              ref={formRef}
            >
              <input
                type="hidden"
                name="learns"
                value={JSON.stringify(learns)}
              />
              <div className="p-4 md:p-5 space-y-4">
                {currentForm === 0 && (
                  <>
                    <p className="mb-5 text-sm font-medium text-gray-900">
                      Pilih workhsop
                    </p>
                    <div className="mb-5 flex flex-col gap-2">
                      {classs?.map((dt, idx: number) => (
                        <div className="flex items-center" key={idx}>
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            checked={learns.includes(
                              dt.label.replaceAll(" ", "_")
                            )}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                            onChange={() =>
                              handleChangesLearn(dt.label.replaceAll(" ", "_"))
                            }
                          />
                          <div className="flex justify-between">
                            <label
                              htmlFor="checked-checkbox"
                              className={`ms-2 text-sm font-semibold ${
                                errors.learns ? "text-red-900" : "text-gray-900"
                              }`}
                            >
                              {dt.label}
                            </label>
                            <div className="ms-2 text-xs">
                              - {dt.description[0]}
                              {/* - Biaya Rp. 250.000/group */}
                            </div>
                          </div>
                        </div>
                      ))}
                      <p
                        className={`${
                          errors.learns ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.learns}
                      </p>
                    </div>
                    <p className="my-5 text-sm font-medium text-gray-900">
                      Data Peserta
                    </p>
                    <div className="relative z-0 w-full mb-5 group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className={`block py-2.5 px-0 w-full text-sm ${
                          errors.name ? "" : "text-gray-900"
                        }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        required
                        placeholder=" "
                        autoComplete="off"
                        value={dataForm?.name || ""}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="name"
                        className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                          errors.name
                            ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                            : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                        } `}
                        // className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Nama Peserta
                      </label>
                      <p
                        className={`${
                          errors.name ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.name}
                      </p>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="text"
                          name="place_of_birth"
                          id="place_of_birth"
                          className={`block py-2.5 px-0 w-full text-sm ${
                            errors.place_of_birth ? "" : "text-gray-900"
                          }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                          // className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                          autoComplete="off"
                          value={dataForm?.place_of_birth || ""}
                          onChange={handleChange}
                          // onChange={(text) =>
                          //   setDataForm({
                          //     ...dataForm,
                          //     place_of_birth: text.target.value,
                          //   })
                          // }
                        />
                        <label
                          htmlFor="place_of_birth"
                          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                            errors.place_of_birth
                              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                          } `}
                          // className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Tempat Lahir
                        </label>
                        <p
                          className={`${
                            errors.place_of_birth ? "block" : "hidden"
                          } mt-2 text-sm text-red-600 dark:text-red-500`}
                        >
                          {errors?.place_of_birth}
                        </p>
                      </div>
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="date"
                          name="date_of_birth"
                          id="date_of_birth"
                          className={`block py-2.5 px-0 w-full text-sm ${
                            errors.date_of_birth ? "" : "text-gray-900"
                          }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                          // className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                          autoComplete="off"
                          value={dataForm?.date_of_birth || ""}
                          onChange={handleChange}
                          // onChange={(text) =>
                          //   setDataForm({
                          //     ...dataForm,
                          //     date_of_birth: text.target.value,
                          //   })
                          // }
                        />
                        <label
                          htmlFor="date_of_birth"
                          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                            errors.date_of_birth
                              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                          } `}
                          // className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Tanggal Lahir
                        </label>
                        <p
                          className={`${
                            errors.date_of_birth ? "block" : "hidden"
                          } mt-2 text-sm text-red-600 dark:text-red-500`}
                        >
                          {errors?.date_of_birth}
                        </p>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className={`block py-2.5 px-0 w-full text-sm ${
                            errors.city ? "" : "text-gray-900"
                          }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                          // className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                          autoComplete="off"
                          value={dataForm?.city || ""}
                          onChange={handleChange}
                          // onChange={(text) =>
                          //   setDataForm({
                          //     ...dataForm,
                          //     city: text.target.value,
                          //   })
                          // }
                        />
                        <label
                          htmlFor="city"
                          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                            errors.city
                              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                          } `}
                          // className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Asal Kota
                        </label>
                        <p
                          className={`${
                            errors.city ? "block" : "hidden"
                          } mt-2 text-sm text-red-600 dark:text-red-500`}
                        >
                          {errors?.city}
                        </p>
                      </div>
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="text"
                          name="choir_name"
                          id="choir_name"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                          autoComplete="off"
                          value={dataForm?.choir_name || ""}
                          onChange={handleChange}
                          // onChange={(text) =>
                          //   setDataForm({
                          //     ...dataForm,
                          //     choir_name: text.target.value,
                          //   })
                          // }
                        />
                        <label
                          htmlFor="choir_name"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Nama Choir
                        </label>
                      </div>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                      <textarea
                        rows={2}
                        name="address"
                        id="address"
                        className={`block py-2.5 px-0 w-full text-sm ${
                          errors.address ? "" : "text-gray-900"
                        }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        autoComplete="off"
                        value={dataForm?.address || ""}
                        onChange={handleChange}
                        // onChange={(text) =>
                        //   setDataForm({
                        //     ...dataForm,
                        //     address: text.target.value,
                        //   })
                        // }
                      />
                      <label
                        htmlFor="address"
                        className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                          errors.address
                            ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                            : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                        } `}
                        //className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Alamat Peserta
                      </label>
                      <p
                        className={`${
                          errors.address ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.address}
                      </p>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                      <input
                        type="phone"
                        name="contact"
                        id="contact"
                        className={`block py-2.5 px-0 w-full text-sm ${
                          errors.contact ? "" : "text-gray-900"
                        }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        autoComplete="off"
                        value={dataForm?.contact || ""}
                        onChange={handleChange}
                        // onChange={(text) =>
                        //   setDataForm({
                        //     ...dataForm,
                        //     contact: text.target.value,
                        //   })
                        // }
                      />
                      <label
                        htmlFor="contact"
                        className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                          errors.contact
                            ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                            : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                        } `}
                        // className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        No.HP/WA
                      </label>
                      <p
                        className={`${
                          errors.contact ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.contact}
                      </p>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className={`block py-2.5 px-0 w-full text-sm ${
                          errors.email ? "" : "text-gray-900"
                        }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        autoComplete="off"
                        value={dataForm?.email || ""}
                        onChange={handleChange}
                        // onChange={(text) =>
                        //   setDataForm({
                        //     ...dataForm,
                        //     email: text.target.value,
                        //   })
                        // }
                      />
                      <label
                        htmlFor="email"
                        className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                          errors.email
                            ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                            : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                        } `}
                        // className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Email address
                      </label>
                      <p
                        className={`${
                          errors.email ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.email}
                      </p>
                    </div>
                  </>
                )}
                {currentForm === 1 && (
                  <>
                    <p>
                      Sillakan melakukan pembayaran ke rekening:{" "}
                      <ol>
                        <li>Bank: BCA</li>
                        <li>Norek: 0218888987 </li>
                        <li>Atas Nama: Colours Choirs </li>
                        <li>
                          Nominal: Rp.{" "}
                          {totalPayment
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        </li>
                      </ol>
                      dan beri keterangan Transfer{" "}
                      <b>NamaChoir_AsalChoir_PCE2024</b>
                    </p>
                    <p className="mb-5 text-sm font-medium text-gray-900 ">
                      Data Rekening
                    </p>
                    <input type="hidden" name="nominal" value={totalPayment} />
                    <input type="hidden" name="name" value={dataForm?.name} />
                    <input
                      type="hidden"
                      name="place_of_birth"
                      value={dataForm?.place_of_birth}
                    />
                    <input
                      type="hidden"
                      name="date_of_birth"
                      value={dataForm?.date_of_birth}
                    />
                    <input type="hidden" name="city" value={dataForm?.city} />
                    <input
                      type="hidden"
                      name="choir_name"
                      value={dataForm?.choir_name}
                    />
                    <input
                      type="hidden"
                      name="address"
                      value={dataForm?.address}
                    />
                    <input
                      type="hidden"
                      name="contact"
                      value={dataForm?.contact}
                    />
                    <input type="hidden" name="email" value={dataForm?.email} />
                    <div className="relative z-0 w-full mb-5 group">
                      <input
                        type="text"
                        name="bank"
                        id="bank"
                        className={`block py-2.5 px-0 w-full text-sm ${
                          errors.bank ? "" : "text-gray-900"
                        }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        autoComplete="off"
                        value={dataForm?.bank || ""}
                        onChange={handleChange}
                        // onChange={(text) =>
                        //   setDataForm({
                        //     ...dataForm,
                        //     bank: text.target.value,
                        //   })
                        // }
                      />
                      <label
                        htmlFor="bank"
                        className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                          errors.bank
                            ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                            : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                        } `}
                        // className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Nama Bank*
                      </label>
                      <p
                        className={`${
                          errors.bank ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.bank}
                      </p>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                      <input
                        type="text"
                        name="owner_rek"
                        id="owner_rek"
                        className={`block py-2.5 px-0 w-full text-sm ${
                          errors.owner_rek ? "" : "text-gray-900"
                        }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        autoComplete="off"
                        value={dataForm?.owner_rek || ""}
                        onChange={handleChange}
                        // onChange={(text) =>
                        //   setDataForm({
                        //     ...dataForm,
                        //     owner_rek: text.target.value,
                        //   })
                        // }
                      />
                      <label
                        htmlFor="owner_rek"
                        className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                          errors.owner_rek
                            ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                            : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                        } `}
                        // className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Nama Pemilik Bank*
                      </label>
                      <p
                        className={`${
                          errors.owner_rek ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.owner_rek}
                      </p>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                      <input
                        type="file"
                        accept=".pdf"
                        name="file_bill"
                        id="file_bill"
                        className={`block py-2.5 px-0 w-full text-sm ${
                          errors.file_bill ? "" : "text-gray-900"
                        }  bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        autoComplete="off"
                        onChange={handleFile}
                        // onChange={(fl) =>
                        //   setDataForm({
                        //     ...dataForm,
                        //     file_bill: fl.target?.files
                        //       ? fl.target?.files[0]
                        //       : null,
                        //   })
                        // }
                      />
                      <label
                        htmlFor="file_bill"
                        className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
                          errors.file_bill
                            ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                            : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
                        } `}
                        // className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Upload Bukti Pembayaran*
                      </label>
                      <p
                        className={`${
                          errors.file_bill ? "block" : "hidden"
                        } mt-2 text-sm text-red-600 dark:text-red-500`}
                      >
                        {errors?.file_bill}
                      </p>
                    </div>
                  </>
                )}
                {currentForm === 2 && (
                  <div className="py-9">
                    <div className="w-12 h-12 rounded-full bg-green-100 p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-green-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Success</span>
                    </div>
                    <p className="mb-4 text-sm font-light text-gray-900 text-center">
                      {actionRes?.data?.message}
                    </p>
                  </div>
                )}
              </div>
              {/* <!-- Modal footer --> */}
              <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <div>
                  {currentForm === 0 && (
                    <>
                      Total:
                      <br />
                      Rp.{" "}
                      {totalPayment
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    </>
                  )}
                </div>
                <div className="flex">
                  {currentForm === 0 && (
                    <button
                      // disabled={
                      //   dataForm?.workshop === undefined ||
                      //   dataForm?.workshop?.length === 0
                      // }
                      data-modal-hide="default-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={handleBtnSubmit}
                    >
                      Bayar
                    </button>
                  )}
                  {currentForm === 1 && (
                    <div className="flex gap-4">
                      <button
                        data-modal-hide="default-modal"
                        type="button"
                        className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={() => {
                          setCurrentForm(0);
                        }}
                      >
                        Kembali
                      </button>
                      <button
                        disabled={state === "submitting" || state === "loading"}
                        data-modal-hide="default-modal"
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={handleBtnSubmit}
                      >
                        Simpan
                      </button>
                    </div>
                  )}
                  {currentForm === 2 && (
                    <>
                      <button
                        data-modal-hide="default-modal"
                        disabled={currentForm === 2}
                        type="button"
                        className="flex gap-2 items-center py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-lg border border-gray-200 not:disabled:hover:bg-gray-100 not:disabled:hover:text-blue-700 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none focus:z-10 focus:ring-4 focus:ring-gray-100 "
                        onClick={() => setShowModal(false)}
                      >
                        Selesai
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassPage;
