import { LoaderFunctionArgs, redirect } from "react-router-dom";
import axios from "axios";

export async function ticketAction({ request, params }: LoaderFunctionArgs) {
  const formData = await request.formData();
  await axios.post("//API.PCE2024.ORG/" + params.email + "/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return redirect("/admin/tiket");
}
