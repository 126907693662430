import React, { useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLoaderData, useSearchParams } from "react-router-dom";
import DetailMemberWorkshopModal from "../components/modals/DetailMemberWorkshopModal";

const WorkshopPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [showMemberEventModal, setShowMemberEventModal] = useState(false);

  const loader: any = useLoaderData();
  // const submit = useSubmit();

  const handleEdit = (data: any) => {
    setDetails(data);
    setShowMemberEventModal(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePagiantionNav = (e: any) => {
    const { target } = e;
    const operation = target.getAttribute("data-nav-operation");
    const offset = operation === "next" ? 1 : -1;
    const params = new URLSearchParams();
    const currentPage = Number(searchParams.get("page")?.toString()) || 1;
    params.set(
      "page",
      currentPage + offset === 0 ? "1" : (currentPage + offset).toString()
    );
    setSearchParams(params, {
      preventScrollReset: true,
    });
  };

  const navList = [
    { label: "Beranda", url: "/" },
    { label: "Workshop", url: "/class" },
    { label: "Jadwal", url: "/jadwal" },
    { label: "Juri", url: "/juri" },
    { label: "Kontak", url: "/kontak" },
  ];

  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-black opacity-60"></div>
      <Header
        navs={navList}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="bg-black bg-cover h-screen">
        <div className="relative py-8 lg:py-16 px-4 mx-auto max-w-screen-2xl h-full pb-80">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-white">
            Daftar Peserta Workshop
          </h2>
          <table className="table-auto w-full text-sm text-left rtl:text-right text-white bg-black border-white border">
            <thead className="text-xs text-gray-800 uppercase border-b bg-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Nama
                </th>
                <th scope="col" className="px-6 py-3">
                  E-mail
                </th>
                <th scope="col" className="px-6 py-3">
                  Nomor kontak
                </th>
                <th scope="col" className="px-6 py-3">
                  Bank
                </th>
                <th scope="col" className="px-6 py-3">
                  Pemilik Rekening
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  File
                </th> */}
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {loader?.data?.map((dt: any, idx: number) => (
                <tr className=" text-white border-b" key={idx}>
                  <td className="px-6 py-4 ">{dt.name}</td>
                  <td className="px-6 py-4">{dt.email}</td>
                  <td className="px-6 py-4">{dt.contact}</td>
                  <td className="px-6 py-4">{dt.bank}</td>
                  <td className="px-6 py-4">{dt.owner_rek}</td>
                  <td className="px-6 py-4">
                    {dt.validate ? "Approved" : "Draft"}
                    {/* {Number(dt.nominal)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} */}
                  </td>
                  {/* <td className="px-6 py-4 justify-center">
                    <button
                      onClick={() =>
                        submit(
                          { path: dt.filename },
                          {
                            method: "post",
                            encType: "application/json",
                            action: "/admin/workshop",
                          }
                        )
                      }
                    >
                      <svg
                        className="w-6 h-6 text-blue-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"
                        />
                      </svg>
                    </button>
                  </td> */}
                  <td className="px-6 py-4 justify-center">
                    <button onClick={() => handleEdit(dt)}>
                      <svg
                        className="w-5 h-5 text-blue-500 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                // onClick={onActionClick}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                data-nav-operation="previous"
                disabled={parseInt(searchParams.get("page") || "0") < 2}
              >
                Sebelumnya
              </button>
              <button
                // disabled={disableNext}
                // onClick={onActionClick}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                data-nav-operation="next"
              >
                Selanjutnya
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Menampilkan{" "}
                  <span className="font-medium">
                    {(loader?.count === 0
                      ? 0
                      : parseInt(searchParams.get("page") || "0") <= 1
                      ? 1
                      : (parseInt(searchParams.get("page") || "0") - 1) * 10 + 1
                    )
                      .toLocaleString()
                      .replace(",", ".")}
                  </span>{" "}
                  hingga{" "}
                  <span className="font-medium">
                    {loader?.count === 0
                      ? 0
                      : loader?.count <= 10
                      ? loader?.count
                      : (parseInt(searchParams.get("page") || "0") <= 1
                          ? 10
                          : parseInt(searchParams.get("page") || "0") * 10
                        )
                          .toLocaleString()
                          .replace(",", ".")}
                  </span>{" "}
                  dari{" "}
                  <span className="font-medium">
                    {Number(loader?.count).toLocaleString().replace(",", ".") ||
                      0}
                  </span>{" "}
                  data
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={handlePagiantionNav}
                    disabled={parseInt(searchParams.get("page") || "0") < 2}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sebelumnya</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                      data-nav-operation="previous"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>

                    {/* <ChevronLeftIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                      data-nav-operation="previous"
                    /> */}
                  </button>

                  <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                    ...
                  </span>

                  <button
                    type="button"
                    disabled={
                      (Number(searchParams.get("page")?.toString()) || 1) *
                        10 >=
                      loader?.count
                    }
                    onClick={handlePagiantionNav}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Selanjutnya</span>
                    <svg
                      data-nav-operation="next"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
      <DetailMemberWorkshopModal
        open={showMemberEventModal}
        data={details}
        onCancel={() => setShowMemberEventModal(false)}
      />
    </div>
  );
};

export default WorkshopPage;
