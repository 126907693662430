interface IProps {
  show: boolean;
  values: any;
  errors?: any;
  onChange: (e: any) => void;
}

export default function ChoirDetailsCard({
  values,
  onChange,
  show,
  errors,
}: IProps) {
  const handleChange = (e: any) => {
    onChange({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className={show ? "block" : "hidden"}>
      <div className="relative z-0 w-full mb-5 group ">
        <select
          id="category_select"
          name="category"
          value={values?.category || "mixed_choir"}
          onChange={handleChange}
          // className={`${
          //   errors?.category ? "text-red-500" : "text-gray-500"
          // }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
        >
          {/* <option>Pilih kategori</option> */}
          <option value="mixed_choir">Mixed Choir</option>
          <option value="equal_choir">Equal Choir</option>
          <option value="children_choir">Children Choir</option>
          <option value="youth_choir">Youth Choir</option>
          <option value="folklore">Folklore</option>
        </select>
        <label
          htmlFor="category_select"
          // className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
          //   errors?.category
          //     ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
          //     : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          // } `}
          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Kategory
        </label>
        {/* <p
          className={`${
            errors?.category ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.category}
        </p> */}
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="name"
          id="nama_pic"
          className={`${
            errors?.name ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm disabled:text-orange-200 text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          value={values?.name || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="nama_pic"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.name
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          // className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Nama PIC Choir
        </label>
        <p
          className={`${
            errors?.name ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.name}
        </p>
      </div>
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="city"
            id="city"
            className={`${
              errors?.city ? "text-red-500" : "text-gray-500"
            }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
            //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            autoComplete="off"
            value={values?.city || ""}
            onChange={handleChange}
          />
          <label
            htmlFor="city"
            className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
              errors?.city
                ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
            } `}
            //className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Kota
          </label>
          <p
            className={`${
              errors?.city ? "block" : "hidden"
            } mt-2 text-sm text-red-600 dark:text-red-500`}
          >
            {errors?.city}
          </p>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="instance"
            id="instance"
            className={`${
              errors?.instance ? "text-red-500" : "text-gray-500"
            }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
            //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            autoComplete="off"
            value={values?.instance || ""}
            onChange={handleChange}
          />
          <label
            htmlFor="instance"
            className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
              errors?.instance
                ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
                : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
            } `}
            //className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Instansi
          </label>
          <p
            className={`${
              errors?.instance ? "block" : "hidden"
            } mt-2 text-sm text-red-600 dark:text-red-500`}
          >
            {errors?.instance}
          </p>
        </div>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <textarea
          rows={3}
          name="address"
          id="pic_address"
          className={`${
            errors?.address ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          value={values?.address || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="pic_address"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.address
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Alamat PIC
        </label>
        <p
          className={`${
            errors?.address ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.address}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="contact"
          id="pic_contact"
          className={`${
            errors?.contact ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          value={values?.contact || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="pic_contact"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.contact
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          No.HP/WA
        </label>
        <p
          className={`${
            errors?.contact ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.contact}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="email"
          name="email"
          id="floating_email"
          className={`${
            errors?.email ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          value={values?.email || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="floating_email"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.email
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Email
        </label>
        <p
          className={`${
            errors?.email ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.email}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="choir_name"
          id="choir_name"
          className={`${
            errors?.choir_name ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          value={values?.choir_name || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="choir_name"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.choir_name
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Nama Choir
        </label>
        <p
          className={`${
            errors?.choir_name ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.choir_name}
        </p>
      </div>
    </div>
  );
}
