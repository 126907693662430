import React, { useState } from "react";
import { useActionData, useLoaderData } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ChoirRegisterModal from "../components/choir/ChoirRegisterModal";

const HomePage: React.FC = () => {
  const loader: any = useLoaderData();
  const actionRes: any = useActionData();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState(false);

  // const classs = [
  //   {
  //     label: "Mixed Choir",
  //     description: "Usia minimal 17 tahun per September 2024.",
  //   },
  //   {
  //     label: "Equal Choir",
  //     description: "Usia minimal 17 tahun per September 2024.",
  //   },
  //   {
  //     label: "Children Choir",
  //     description: "Usia 6-13 tahun per September 2024.",
  //   },
  //   {
  //     label: "Youth Choir",
  //     description: "Usia 14-20 tahun per September 2024.",
  //   },
  //   {
  //     label: "Folklore",
  //     description:
  //       "Membawakan Lagu Folksong . Usia minimal 17 tahun per September.",
  //   },
  // ];

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gray-800 flex flex-col min-h-screen h-screen">
      <div className="fixed w-screen h-screen bg-gradient-to-t from-black"></div>
      <Header
        navs={loader.navs}
        user={loader?.user}
        show={show}
        onChange={(val: boolean) => setShow(val)}
      />
      <section className="bg-home bg-cover">
        <div className=" relative grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl text-white">
              Palembang Choral Exhibition
            </h1>
            <p className="max-w-2xl  font-light md:text-base lg:text-xl text-gray-400">
              Sebuah pagelaran yang berisi workshop dan kompetisi antar Paduan
              suara. Inti dan tujuan dari diselenggarakannya kegiatan ini adalah
              untuk :
            </p>
            <ul className="list-disc list-outside pl-4 max-w-2xl mb-6 font-light lg:mb-8 md:text-base lg:text-xl text-gray-400">
              <li>
                Merangkul komunitas Paduan suara yang ada di lingkup daerah
                tertentu
              </li>
              <li>
                Memberikan ruang bagi para penggiat Paduan suara untuk menambah
                ilmu
              </li>
              <li>
                Menciptakan iklim kompetisi yang sehat antar komunitas Paduan
                suara
              </li>
            </ul>

            <button
              onClick={() => setIsModalOpen(true)}
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 "
            >
              {/* // <Link
              //   to="/register"
              //   className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 "
              // > */}
              Daftar
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {/* // </Link> */}
            </button>
          </div>

          {/* <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img src={Dashboard} alt="mockup" /> 
        </div> */}
        </div>
        <div className="relative py-8 lg:py-16 mx-auto max-w-screen-xl px-4 text-orange-300">
          <div className="mb-8 max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold leading-tight tracking-tight text-white">
              Kategori Lomba
            </h2>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            {loader.classs.map((dt: any, idx: number) => (
              <div key={idx}>
                <h3 className="mb-2 text-lg font-bold text-gold">{dt.label}</h3>
                <p className="text-gray-400 text-sm">{dt.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="relative py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
          <div className="mb-8 max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold leading-tight tracking-tight text-white">
              Persyaratan Umum
            </h2>
          </div>
          <ul className="space-y-4 text-white list-inside mx-auto">
            {loader?.rules.map((rule: string, idx: number) => (
              <li className="flex items-center text-gray-400" key={idx}>
                <svg
                  className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                {rule}
              </li>
            ))}
          </ul>
        </div>
      </section>
      <Footer />
      <ChoirRegisterModal
        open={isModalOpen}
        action={actionRes}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default HomePage;
