import httpCommon from "./http-common";

export const login = async (data: any) => {
  return await httpCommon.post<any>("/auth/signin", data);
};

export const forgotPassword = async (data: any) => {
  try {
    return await httpCommon.post<any>("/auth/reset-password", data);
  } catch (error: any) {
    return error?.response;
  }
};

export const refreshToken = async (data: any) => {
  try {
    return await httpCommon.put<any>("/auth/refreshtoken" + data.id, data);
  } catch (error: any) {
    return error?.response;
  }
};
