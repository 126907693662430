import { useEffect, useRef, useState } from "react";
import { Form, useNavigation, useSubmit } from "react-router-dom";
import TermCard from "./TermCard";
import BillingCard from "./BillingCard";
import ChoirDetailsCard from "./ChoirDetailsCard";
import ChoirSongCard from "./ChoirSongCard";
import FilesCard from "./FilesCard";

interface IProps {
  open: boolean;
  action: any;
  onOk: () => void;
  onCancel: () => void;
}

export default function ChoirRegisterModal(props: IProps) {
  const { state } = useNavigation();
  const [currentForm, setCurrentForm] = useState(0);
  const [actionRes, setActionRes] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [dataForm, setDataForm] = useState<any>({
    nominal: 2000000,
    policy: false,
  });

  useEffect(() => {
    setActionRes(props?.action);
  }, [props?.action]);

  // const actionRes: any = useActionData();
  const formRef = useRef<any>(null);
  const submit = useSubmit();

  useEffect(() => {
    setCurrentForm(0);
  }, []);

  useEffect(() => {
    if (actionRes?.status === 201) setCurrentForm(5);
  }, [actionRes]);

  const handleBtnSubmit = () => {
    const _errors: any = {};
    if (currentForm === 0) {
      if (!dataForm.policy) {
        _errors.policy = "Policy belum di checklist.";
      }
      if (Object.keys(_errors).length) {
        setErrors({
          ..._errors,
        });
      } else {
        setCurrentForm(1);
      }
    } else if (currentForm === 1) {
      // if (!dataForm.category) {
      //   _errors.category = "Kategori belum di pilih.";
      // }
      if (!dataForm.name) {
        _errors.name = "Nama belum di input.";
      }
      if (!dataForm.city) {
        _errors.city = "Kota belum di input.";
      }
      if (!dataForm.instance) {
        _errors.instance = "Instansi belum di input.";
      }
      if (!dataForm.address) {
        _errors.address = "Alamat belum di input.";
      }
      if (!dataForm.contact) {
        _errors.contact = "Kontak belum di input.";
      }
      if (!dataForm.email) {
        _errors.email = "Email belum di input.";
      }
      if (!dataForm.choir_name) {
        _errors.choir_name = "Nama Choir belum di input.";
      }
      if (Object.keys(_errors).length) {
        setErrors({
          ..._errors,
        });
      } else {
        setCurrentForm(2);
      }
    } else if (currentForm === 2) {
      if (!dataForm.song_1) {
        _errors.song_1 = "Lagu ke 1 belum di input.";
      }
      // if (!dataForm.file_song_1) {
      //   _errors.file_song_1 = "File lagu ke 1 belum di upload.";
      // }
      if (!dataForm.song_2) {
        _errors.song_2 = "Lagu ke 2 belum di input.";
      }
      // if (!dataForm.file_song_2) {
      //   _errors.file_song_2 = "File lagu ke 2 belum di upload.";
      // }
      if (Object.keys(_errors).length) {
        setErrors({
          ..._errors,
        });
      } else {
        setCurrentForm(3);
      }
    } else if (currentForm === 3) {
      if (!dataForm.file_peserta) {
        _errors.file_peserta = "File peserta belum di upload.";
      }
      if (!dataForm.file_choir) {
        _errors.file_choir = "File choir belum di upload.";
      }
      if (!dataForm.file_logo_choir) {
        _errors.file_logo_choir = "File logo choir belum di upload.";
      }
      if (!dataForm.file_ktp) {
        _errors.file_ktp = "File ktp belum di upload.";
      }
      if (Object.keys(_errors).length) {
        setErrors({
          ..._errors,
        });
      } else {
        setCurrentForm(4);
      }
    } else if (currentForm === 4) {
      if (!dataForm.bank) {
        _errors.bank = "Bank belum di input.";
      }

      if (!dataForm.owner_rek) {
        _errors.owner_rek = "Pemilik rekening belum di input.";
      }

      if (!dataForm.file_bill) {
        _errors.file_bill = "File belum di upload.";
      }
      if (Object.keys(_errors).length) {
        setErrors({
          ..._errors,
        });
      } else {
        submit(formRef.current);
      }
    }

    // } else {
    //   if (!dataForm.bank) {
    //     _errors.bank = "Bank belum di input.";
    //   }

    //   if (!dataForm.owner_rek) {
    //     _errors.owner_rek = "Pemilik rekening belum di input.";
    //   }

    //   if (!dataForm.file_bill) {
    //     _errors.file_bill = "File belum di upload.";
    //   }
    //   if (Object.keys(_errors).length) {
    //     setErrors({
    //       ..._errors,
    //     });
    //   } else {
    //     submit(formRef.current);
    //   }
    // }
  };

  return (
    <div
      id="default-modal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.open ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
      <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
      <div className="relative flex items-center min-h-screen px-4 py-8">
        {/* <div className="relative p-4 w-full max-w-4xl max-h-full  mx-auto"> */}
        <div className="relative bg-white w-full max-w-4xl max-h-full rounded-lg shadow mx-auto">
          <div className="flex items-center justify-between px-4 py-2 md:p-5 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-gray-900 ">
              Pendaftaran Choir
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
              data-modal-hide="default-modal"
              onClick={props.onCancel}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {actionRes?.status !== 201 && (
            <div
              className={`${actionRes?.data?.message ? " " : "hidden "} ${
                actionRes?.status === 201
                  ? "text-green-800 bg-green-50 "
                  : "text-red-800 bg-red-50 "
              } p-4 m-4 text-sm  rounded-lg `}
              role="alert"
            >
              {actionRes?.data?.message}
            </div>
          )}

          <Form
            method="POST"
            encType="multipart/form-data"
            action="/"
            ref={formRef}
          >
            <div className="p-4 md:p-5 space-y-4">
              <TermCard
                show={currentForm === 0}
                values={dataForm}
                errors={errors}
                onChange={setDataForm}
              />
              <ChoirDetailsCard
                show={currentForm === 1}
                values={dataForm}
                errors={errors}
                onChange={setDataForm}
              />
              <ChoirSongCard
                show={currentForm === 2}
                values={dataForm}
                errors={errors}
                onChange={setDataForm}
              />
              <FilesCard
                show={currentForm === 3}
                values={dataForm}
                errors={errors}
                onChange={setDataForm}
              />
              <BillingCard
                show={currentForm === 4}
                values={dataForm}
                errors={errors}
                onChange={setDataForm}
              />
              {currentForm === 5 && (
                <div className="py-9">
                  <div className="w-12 h-12 rounded-full bg-green-100 p-2 flex items-center justify-center mx-auto mb-3.5">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-green-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Success</span>
                  </div>
                  <p className="mb-4 text-sm font-light text-gray-900 text-center">
                    {actionRes?.data?.message}
                  </p>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b ">
              <div></div>
              <div className="flex gap-2">
                {currentForm < 5 ? (
                  <>
                    <button
                      disabled={state === "submitting" || state === "loading"}
                      data-modal-hide="default-modal"
                      type="button"
                      className={`${
                        currentForm === 0 && "hidden"
                      } flex gap-2 items-center py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-lg border border-gray-200 not:disabled:hover:bg-gray-100 not:disabled:hover:text-blue-700 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none focus:z-10 focus:ring-4 focus:ring-gray-100 `}
                      onClick={() => {
                        setCurrentForm(() =>
                          currentForm > 0 ? currentForm - 1 : 0
                        );
                      }}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14M5 12l4-4m-4 4 4 4"
                        />
                      </svg>{" "}
                      Kembali
                    </button>
                    {/* {currentForm <= 3 ? ( */}
                    <button
                      disabled={state === "submitting" || state === "loading"}
                      data-modal-hide="default-modal"
                      type="button"
                      // "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      className={`flex gap-2 items-center py-2.5 px-5 ms-3 text-sm font-medium ${
                        currentForm <= 3
                          ? "text-gray-900 bg-gray-300 "
                          : "text-white bg-blue-700 "
                      } focus:outline-none  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 `}
                      onClick={handleBtnSubmit}
                      //   () => {
                      //   setCurrentForm(() => currentForm + 1);
                      // }}
                    >
                      {currentForm <= 3 ? (
                        <>
                          Selanjutnya{" "}
                          <svg
                            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </>
                      ) : (
                        "Simpan"
                      )}
                    </button>
                    {/* ) : (
                      <button
                        data-modal-hide="default-modal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Simpan
                      </button>
                    )} */}
                  </>
                ) : (
                  <>
                    <button
                      data-modal-hide="default-modal"
                      disabled={currentForm === 0}
                      type="button"
                      className="flex gap-2 items-center py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-lg border border-gray-200 not:disabled:hover:bg-gray-100 not:disabled:hover:text-blue-700 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none focus:z-10 focus:ring-4 focus:ring-gray-100 "
                      onClick={props.onCancel}
                    >
                      Selesai
                    </button>
                  </>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
