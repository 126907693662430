interface IProps {
  label: string;
  value: string;
  onFilePreview?: () => void;
  onOpenPreview?: (show: boolean) => void;
}

export const Item = ({
  label,
  value,
  onFilePreview,
  onOpenPreview,
}: IProps) => {
  const handlePreview = () => {
    onFilePreview && onFilePreview();
    onOpenPreview && onOpenPreview(true);
  };

  return (
    <div className="font-medium ">
      <div>{label}</div>
      <div className="flex gap-4 text-sm text-gray-500 ">
        {`${value || "-"}`}{" "}
        {onOpenPreview && (
          <button
            onClick={handlePreview}
            className="text-blue-600 items-center flex"
          >
            <svg
              className="w-4 h-4 text-blue-600 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};
