import { LoaderFunctionArgs, json } from "react-router-dom";

import httpCommon from "./http-common";

export async function workshopAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());

  data.id =
    data.id === undefined || data.id === "undefined" ? undefined : data.id;
  const response = await updateWorkshop(data);
  return json({ ...response });
  // return redirect("/admin/workshop");
}

export const updateWorkshop = async (data: any) => {
  try {
    return await httpCommon.put("/workshop/" + data?.id, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error: any) {
    return error?.response;
  }
};

export const downloadWorkshop = async (data: any) => {
  try {
    return await httpCommon.post("/workshop/download", data, {
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      responseType: "blob",
    });
  } catch (error: any) {
    return error?.response;
  }
};
