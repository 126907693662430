interface IProps {
  show: boolean;
  values: any;
  errors?: any;
  onChange: (e: any) => void;
}
export default function BillingCard({
  values,
  onChange,
  show,
  errors,
}: IProps) {
  const handleChange = (e: any) => {
    onChange({ ...values, [e.target.name]: e.target.value });
  };

  const handleFile = (e: any) => {
    onChange({ ...values, [e.target.name]: e.target.files[0] });
  };
  return (
    <div className={`${show ? "block" : "hidden"} space-y-4`}>
      <div>
        <p className="text-sm font-light">
          Sillakan melakukan pembayaran ke rekening:{" "}
        </p>
        <ol className="ml-4">
          <li>Bank: BCA</li>
          <li>Norek: 0218888987 </li>
          <li>Atas Nama: Colours Choirs </li>
          <li>
            Nominal: Rp.{" "}
            {Number(values?.nominal)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </li>
        </ol>
        <p className="text-sm font-light">
          dan beri keterangan Transfer <b>NamaChoir_AsalChoir_PCE2024</b>
        </p>
      </div>
      <p className="mb-5 text-sm font-medium text-gray-900 ">Data Rekening</p>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="bank"
          id="bank"
          className={`${
            errors?.bank ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          autoComplete="off"
          value={values?.bank || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="bank"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.bank
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Nama Bank*
        </label>
        <p
          className={`${
            errors?.bank ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.bank}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="owner_rek"
          id="owner_rek"
          className={`${
            errors?.owner_rek ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          autoComplete="off"
          value={values?.owner_rek || ""}
          onChange={handleChange}
        />
        <label
          htmlFor="owner_rek"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.owner_rek
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Nama Pemilik Bank*
        </label>
        <p
          className={`${
            errors?.owner_rek ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.owner_rek}
        </p>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="file"
          accept=".pdf"
          name="file_bill"
          id="file_bill"
          className={`${
            errors?.file_bill ? "text-red-500" : "text-gray-500"
          }  block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
          //className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          autoComplete="off"
          onChange={handleFile}
        />
        <label
          htmlFor="file_bill"
          className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
            errors?.file_bill
              ? "text-red-700 peer-focus:border-red-600 peer-focus:text-red-600 peer-focus:dark:text-red-500 "
              : "text-gray-500 peer-focus:border-blue-600 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 "
          } `}
          //className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Upload Bukti Pembayaran*
        </label>
        <p
          className={`${
            errors?.file_bill ? "block" : "hidden"
          } mt-2 text-sm text-red-600 dark:text-red-500`}
        >
          {errors?.file_bill}
        </p>
      </div>
    </div>
  );
}
